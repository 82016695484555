import styles from "./dashboard.module.scss";
import {useApp} from "../../AppProvider";
import groupArray from "../../misc/groupArray";
import Social from "../../components/social";

export default function Dashboard({questions}) {
    const app = useApp();

    const remaining = questions.filter(q => !q.answered).length;
    const referral_remaining = 3 - app.data.referral_count;

    const answersToGive = questions.length + app.data.referral_count

    const {user} = app.data;

    return <div className={styles.Dashboard}>
        <div className={"main"}>
            <div>
                <div className={"user-header"}>
                    <p className={"user"}><strong>Olá,</strong> {app.data.user.name}</p>
                    <a onClick={app.logout}>Sair</a>
                </div>
                <div>
                    {app.data.participation?.ended_at ?
                        <h5><strong>Já estás mais perto de L.A.</strong></h5>
                        : (app.data.quiz.ended ?
                                <h5>O passatempo terminou</h5> :
                                (user.random_participation ?
                                    <h5 style={{marginBottom: "50px"}}>Confere a nossa Sugestão de Palpites.</h5> :
                                    <h5>Tens {remaining} {remaining === 1 ? "resposta" : "respostas"} para dar.
                                        {referral_remaining > 0 && <><br/>Partilha com {referral_remaining} amigo{referral_remaining === 1 ? "" : "s"} e ganha mais {referral_remaining}.</>}
                                    </h5>)
                        )}
                    {!user?.random_participation && <Social/>}
                    {app.data.participation?.ended_at ? <p>
                        Agora, só tens de esperar até ao final da cerimónia para saber como te saíste. <br className={"hide-mobile-palpites"}/>
                        Em março, anunciamos o grande vencedor. A melhor parte? <br className={"hide-mobile-palpites"}/>
                        Podes ser tu (e a tua companhia).
                    </p> : (app.data.quiz.ended ?
                        <p>Em breve, anunciamos o grande vencedor.</p> :
                        <>
                            <p style={{maxWidth: "90%"}}>Transforma os teus bitaites sobre os Óscares em 23 palpites certeiros. Escolhe o Modo Palpites (tu é que decides cada voto, se estiveres confiante) ou fica-te
                                pelo Modo Bitaites (voto aleatório, para os mais indecisos).
                            </p>
                        </>)}

                </div>
            </div>
        </div>
        {remaining !== questions.length && <div className={"app-container"}>
            <h3 style={{color: "#00A6E4"}}>{user.random_participation ? "SUGESTÃO DE PALPITES" : "AS TUAS ESCOLHAS"}</h3>
            <div className={"choices"}>
                {groupArray(questions.map((q, idx) => ({...q, idx})), 2).map(group => <div>
                    {group.map(question => <p className={question.answered ? "voted" : ""} onClick={!app.data.participation?.ended_at && (() => app.setGameOpen(question.idx))}>
                        <span>{question.idx + 1}.</span> {question.text}
                        {question.answered && <> - {question.answer?.map(a => a.text.replace("_", " / ")).join(", ")}</>}
                    </p>)}
                </div>)}
            </div>
        </div>}
        {!app.data.quiz.ended && (!app.data.participation?.ended_at && <>{(remaining === 0 && !app.data.participation?.ended_at ? <div className={"footer app-container"}>
            {!!user?.random_participation && <div>
                <h4>Não estás satisfeito?</h4>
                <a className={"button blue"} onClick={() => app.generateRandomChoices()}>Nova sugestão</a>
            </div>}
            {!user?.random_participation && <div>
                <h4>Mudaste de ideias?</h4>
                <a className={"button blue"} onClick={() => {
                    app.setGameOpen(true)
                    setTimeout(() => {
                        window.scroll({top: 0, behavior: 'smooth'})
                    }, 400)
                }}>Alterar votação</a>
            </div>}
            <div>
                <h4>Terminaste?</h4>
                <a className={"button blue"} onClick={() => app.submitQuiz(true)}>Submeter votação</a>
            </div>
            {!!user?.random_participation && <div>
                <h4>Quero escolher os meus Palpites</h4>
                <a className={"button blue"} onClick={app.startNormalTrack}>Participar</a>
            </div>}
        </div> : <div className={"footer app-container no-questions" + (remaining === questions.length ? " no-answers" : "")}>
            <div>
                <div><a className={"button blue"} style={{marginTop: "auto"}} onClick={() => {
                    app.setGameOpen(true);
                    if (remaining !== questions.length) {
                        setTimeout(() => {
                            window.scroll({top: 0, behavior: 'smooth'})
                        }, 400)
                    }
                }}>{remaining === questions.length ? 'Modo Palpites' : 'Continuar desafio'}</a></div>
                {!user?.random_participation && <div>
                    <a className={"button blue"} onClick={() => app.generateRandomChoices(remaining < questions.length)}>Modo Bitaites</a>
                </div>}
            </div>
        </div>)}</>)}

        <div className={"actions app-container"}>
            <a href={"https://canalhollywood.pt/wp-content/uploads/2025/01/HWD_Palpites_Oscares_2025_regulamento-2.pdf"} target={"_blank"}>Ver regulamento</a>
            <a href={"mailto:apoioaocliente@palpitesdososcares.pt"}>apoioaocliente@palpitesdososcares.pt</a>
        </div>
    </div>
}
