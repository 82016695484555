import styles from './auth.module.scss';
import {useApp} from "../../AppProvider";
import {useForm} from "react-hook-form";
import React, {useState} from "react";

export default function Register() {
    let app = useApp();
    let {register, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            terms: true
        }
    });
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [success, setSuccess] = useState(false);

    async function submit(values) {
        setLoading(true);
        setServerError(false);
        let search = new URLSearchParams(window.location.search);
        let referral = search.get("referral");
        if (referral)
            values.referral = referral.trim().toUpperCase();
        values.query = [...search.entries()];
        try {
            await app.register(values);
            setSuccess({email: values.email});
        } catch (e) {
            console.log("e submit: ", e)
            setServerError(e.message);
        }
        setLoading(false);
    }


    let error = serverError || errors.tos?.message || errors.terms?.message;

    let content = <React.Fragment key={"registo"}>
        <h2 style={{marginBottom: "30px"}}>DEIXA-TE DE <br/>BITAITES E VAI A L.A.</h2>
        <label data-error={errors.name?.message}>
            <input {...register("name", {
                required: "O nome é obrigatório"
            })} placeholder={"Nome*"}/>
        </label>
        <label data-error={errors.email?.message}>
            <input {...register("email", {
                required: "O e-mail é obrigatório",
                pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "O e-mail não é válido"
                }
            })} type="email" placeholder={"E-mail*"}/>
        </label>
        <label data-error={errors.phone?.message}>
            <input {...register("phone", {
                required: "O telefone é obrigatório",
                validate: value => {
                    let regex = new RegExp("^(2[1-9]|9[1-356])\\d{7}$");
                    if (!regex.test(value))
                        return "O telefone não é válido";
                    return true;
                }
            })} type={"number"} placeholder={"Telefone*"}/>
        </label>
        <p>Se já estás registado, entra <a onClick={() => {
            app.showLogin();
            app.hideRegister()
        }}>aqui</a>.</p>
        <div className={"bottom"}>
            <div>
                <p style={{fontSize: "0.8em", marginBottom: "1em"}}>
                    Tomei conhecimento de que estes dados serão tratados pela Dreamia e que são necessários para a participação no Passatempo. Ao preencher este formulário, declaro que tenho uma idade maior ou igual a 18 (dezoito) anos. Estes dados são guardados por um período máximo de 6 (seis) meses após a conclusão do Passatempo.
                </p>
                <label className={"checkbox"}>
                    <input type={"checkbox"} {...register("tos", {
                        required: "Concorde com os termos e condições",
                    })}/>
                    <span className={"toggle"}/><span>Li e aceito os termos do <a href={"https://canalhollywood.pt/wp-content/uploads/2025/01/HWD_Palpites_Oscares_2025_regulamento-2.pdf"} target={"_blank"}>Regulamento</a> do Passatempo e autorizo o tratamento dos meus dados pessoais nos termos aí indicados.*</span>
                </label>
                <label className={"checkbox"}>
                    <input type={"checkbox"} {...register("marketing")}/>
                    <span className={"toggle"}/>
                    <span>Aceito o tratamento dos meus dados pessoas de contacto pela Dreamia para efeitos de subscrição das suas newsletters.
                        Tomei conhecimento de que, com a minha autorização, a Dreamia irá tratar os meus dados pessoais de contacto para efeitos de subscrição das suas newsletters, até que cancele esta subscrição e os meus dados pessoais de identificação e de contrato para efeitos de envio de comunicações de produtos, serviços e eventos, durante 24 (vinte e quatro) meses. Para mais informações sobre como exercer os seus direitos, por favor consulte a nossa Política de Privacidade de Cliente <a href={"https://dreamia.pt/politica-de-privacidade/"} target={"_blank"}>aqui</a>.
                </span>
                </label>
                <label className={"checkbox"}>
                    <input type={"checkbox"} {...register("products")}/>
                    <span className={"toggle"}/><span>Aceito o tratamento dos meus dados pessoais de identificação e de contacto pela Dreamia para efeitos de envio de comunicações de produtos, serviços e eventos.</span>
                </label>
            </div>
            <button className={"button"} onClick={handleSubmit(submit)}>Entrar</button>
        </div>
        {error && <p className={"error"}>{error}</p>}
    </React.Fragment>;

    if (success)
        content = <React.Fragment key={"sucesso"}>
            <h2>Está quase</h2>
            <p className={"mt-1"}>Verifica o teu e-mail ({success.email}) para aceder à votação</p>
        </React.Fragment>

    return <div className={styles.Auth} onClick={app.hideRegister}>
        <div className={"form" + (error ? " with-error" : "")} onClick={e => e.stopPropagation()}>
            <div className={"loading" + (loading ? " visible" : "")}>
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
            <span className={"close"} onClick={app.hideRegister}/>
            {content}
        </div>
    </div>
}
