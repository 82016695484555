import {get, post} from "../../helpers";
import {useEffect, useState} from "react";
import styles from './styles.module.scss';
import useBreakpoint from "use-breakpoint";
import Collapsible from "react-collapsible";
import parseHTML from 'html-react-parser';
import {useApp} from "../../AppProvider";
import {Element, scroller} from 'react-scroll';

const BREAKPOINTS = {1: 0, 2: 300, 3: 768, 4: 900, 5: 1280};

function Details({details, hideDetails}) {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(details.show);

        if (details.show)
            scroller.scrollTo("details_" + details.option.id, {
                duration: 300,
                delay: 20,
                smooth: true,
                offset: window.innerWidth <= 800 ? -130 : -112
            });
    }, [details.show]);

    return <>
        <Collapsible key={details.option.id}
                     classParentString={"details " + details.type + (open ? " open" : "") + " "}
                     open={open}
                     trigger={<></>}
                     contentInnerClassName={"s"}
                     contentHiddenWhenClosed={!open}>
            <span className={"close"} onClick={() => hideDetails(details)}/>
            {details.type === "video" && <iframe src={details.option.video + "?autoplay=1&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&rel=0"}/>}
            {details.type === "sinopse" && <div>
                <h4>Sinopse</h4>
                <h5>{details.option.text.replace("_", " / ")}</h5>
                {parseHTML(details.option.description)}
            </div>}
        </Collapsible>
        <Element name={"details_" + details.option.id}/>
    </>
}

export default function Question({question, current, onAnswer}) {
    const {breakpoint, maxWidth, minWidth} = useBreakpoint(BREAKPOINTS, 'desktop');
    const [options, setOptions] = useState(null);
    const [details, setDetails] = useState({});
    const app = useApp();

    useEffect(() => {
        get(`quiz/${question.quiz_id}/question/${question.id}`).then(setOptions);
    }, [question]);

    useEffect(() => {
        if (!current)
            setDetails({});
    }, [current])

    if (!options)
        return null;

    async function vote(option, force = false) {
        await post(`quiz/${question.quiz_id}/question/${question.id}/answer`, {
            answer_id: option.id,
            force: force ? "true" : undefined
        }).then(data => {
            app.setLoading(false)
            setOptions(data.question);
            onAnswer(data.question.answers.filter(a => a.selected));
        }).catch(e => {
            app.setLoading(false)
            let data = e.response?.data;
            app.setPopup(<>
                <h3>Erro</h3>
                <p>{data.message}</p>
                <a className={"button"} onClick={() => app.setPopup(null)}>Fechar</a>
            </>);
            throw e;
        });
    }

    function showDetails(option, pos, type) {
        let gpos = getDetailsPos(pos);
        Object.keys(details).forEach(key => {
            if (getDetailsPos(key) === gpos)
                delete details[key];
            if (details[key])
                details[key].show = false;
        });
        details[pos] = {option, pos, type, show: true};
        setDetails({...details});
    }

    function hideDetails(d) {
        details[d.pos].show = false;
        setDetails({...details});
        //stop iframe video
        if (d.type === "video")
            document.querySelector(".details .open iframe").contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }

    function getDetailsPos(pos) {
        pos = Math.floor(pos / breakpoint) * breakpoint + parseInt(breakpoint) - 1;
        return pos >= options.answers.length ? options.answers.length - 1 : pos;
    }

    let detailsGroups = [];
    Object.keys(details).forEach(p => {
        let pos = getDetailsPos(p);
        detailsGroups[pos] = details[p];
    });

    return <div className={styles.Question}>
        <div className={"options col_" + breakpoint}>
            {options.answers.map((option, pos) => {
                let [title, subtitle] = option.text.split("_");
                let detail_collapsible = null;
                if (detailsGroups[pos])
                    detail_collapsible = <Details details={detailsGroups[pos]} hideDetails={hideDetails}/>;
                return <>
                    <div className={"option " + (option.selected ? "selected" : "") + ((detailsGroups.find(d => d?.show) && !details[pos]) ? " red" : "")}>
                        <div className={"img" + (option.video ? " with_video" : "")} onClick={option.video && (() => showDetails(option, pos, "video"))}>
                            <img src={option.media}/>
                        </div>
                        <p>{title}{subtitle && <><br/>{subtitle}</>}</p>
                        <div className={"actions"}>
                            <a className={"button blue"} onClick={() => vote(option)}>{option.selected ? "Anular" : "Votar"}</a>
                            <span className={"sinopse"} onClick={() => showDetails(option, pos, "sinopse")}/>
                        </div>
                    </div>
                    {detail_collapsible}
                </>
            })}
        </div>
    </div>;
}
