import React, {useEffect, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./landing.module.scss";
import background_2 from "./assets/hollywood_background.png";
import homepageHero from "./assets/hero_background.png";
import logoPalpites from "./assets/logo_palpites.svg";
import logoPalpites2 from "./assets/logo_palpites_2.svg";
import icon_aviao from "./assets/aviao.svg";
import icon_hotel from "./assets/hotel.svg";
import icon_camera from "./assets/camera.svg";
import icon_lua from "./assets/lua.svg";
import clock_blue from "../../assets/icons/clock_blue.svg";
import clock_dark_blue from "../../assets/icons/clock_dark_blue.svg";
import logoRadioComercial from "../../assets/logocomercial.png";
import {useApp} from "../../AppProvider";
import {get} from "../../helpers";
import Marquee from "react-fast-marquee";


export default function Index({}) {
    const app = useApp();
    const [mostVoted, setMostVoted] = useState(null);
    const oscaresDate = new Date(app.data?.quiz?.ceremony_date);
    const [timeLeft, setTimeLeft] = useState((oscaresDate.getTime() - new Date().getTime()) / 1000);
    const [timeLeftToQuiz, setTimeLeftToQuiz] = useState((new Date(app.data?.quiz?.starts_at).getTime() - new Date().getTime()) / 1000);
    const [playMarquee, setPlayMarquee] = useState(null);


    useEffect(() => {
        get(`quiz/${app.data?.quiz?.id}/most-voted-answers`).then(res => setMostVoted(res.most_voted_answers)).catch(e => console.error("error getting most voted answers", e))
    }, []);


    useEffect(() => {
        if (timeLeft <= 0) {
            return;
        }

        // Set up the timer
        const timer = setInterval(() => {
            setTimeLeft((prevSeconds) => prevSeconds - 1);
            setTimeLeftToQuiz((prevSeconds) => prevSeconds - 1);
        }, 1000);

        // Clean up the timer
        return () => clearInterval(timer);
    }, []);

    const formatTimeLeft = (time) => {
        //return in days, hours, minutes and seconds
        let days = Math.floor(time / (60 * 60 * 24));
        let hours = Math.floor((time / (60 * 60)) % 24);
        let minutes = Math.floor((time / 60) % 60);
        let seconds = Math.floor(time % 60);
        if (hours < 10)
            hours = "0" + hours;
        if (minutes < 10)
            minutes = "0" + minutes;
        if (seconds < 10)
            seconds = "0" + seconds;

        return {
            days,
            hours,
            minutes,
            seconds,
            text: `${days} DIA${days !== 1 && "S"} ${hours} HORA${hours !== "01" ? "S" : ""} ${minutes} MINUTOS  ${seconds} SEGUNDOS `
        }
    }

    const timeTrans = {
        days: "Dias",
        hours: "HORAS",
        minutes: "MINUTOS",
        seconds: "SEGUNDOS"
    }

    function showMostVotedSection() {
        const startDate = new Date(app.data.quiz.starts_at);
        const currentDate = new Date();

        const differenceInMilliseconds = currentDate - startDate;

        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

        return mostVoted?.length > 0 && differenceInDays >= 3;
    }

    return <div className={styles.Landing}>
        <div className={"hero" + (timeLeftToQuiz > 0 ? " countdown" : "")}>
            <img className={"illustration"} src={homepageHero} alt={"illustration"}/>
            {timeLeftToQuiz <= 0 && <img src={logoPalpites2} alt={"Palpites dos Óscares"} className={"logo hide-desktop-palpites"}/>}
            <div className={"container app-container"}>
                <div className={"hero-copy"}>
                    <div className={"title" + (timeLeftToQuiz <= 0 ? " quiz-start" : "")}>
                        {timeLeftToQuiz > 0 ?
                            <>
                                <img src={logoPalpites} alt={"Palpites dos Óscares"}/>
                                <h2 className={"transparent-text"}>DEIXA-TE DE BITAITES</h2>
                                <p className={"subtitle"}>SÓ OS MELHORES PALPITES<br/>TE LEVAM A L.A.</p>
                            </> :
                            <>
                                <div>
                                    <h1 className={"transparent-text"}>DEIXA-TE<br/>DE BITAITES</h1>
                                    <h1>E VAI A L.A.</h1>
                                </div>
                                <img src={logoPalpites2} alt={"Palpites dos Óscares"} className={"logo hide-mobile-palpites"}/>
                            </>}
                    </div>
                    {timeLeftToQuiz <= 0 && <>
                        <p className={"subtitle simple-text"}>Acerta nos Palpites dos Óscares e ganha uma<br/>viagem para duas pessoas a Los Angeles.</p>
                        <div className={"buttons"}>
                            <button id={"element-id"} className={"button"} onClick={() => app.showRegister()}>participar</button>
                            <button className={"button"} onClick={() => app.showLogin()}>ENTRAR</button>
                        </div>
                    </>}


                </div>
            </div>
        </div>
        {timeLeftToQuiz > 0 && <div className={"quiz-countdown"}>
            <div className={"app-container"}>
                {Object.keys(formatTimeLeft(timeLeftToQuiz)).filter(k => k !== "text" && k !== "days").map((key, index) => {
                    return <div key={"countdown-" + index}>
                        <h3>{formatTimeLeft(timeLeftToQuiz)[key]}</h3>
                        <h4 style={{textTransform: "none"}}>{timeTrans[key]}</h4>
                    </div>
                })}
            </div>

        </div>}
        {timeLeftToQuiz <= 0 && <>
            <div className={"most-voted"}>
                <div className={"month-label"}>
                    <Marquee autoFill={false} speed={50}><h3><span>PALPITES DOS ÓSCARES 2025</span><span>•</span><span>FALTAM {formatTimeLeft(( new Date(app.data?.quiz?.ends_at).getTime() - new Date().getTime()) / 1000).text}</span></h3></Marquee>
                </div>
                {showMostVotedSection() && <div className={"content container-medium"}>
                    <div className={"content__title"}>
                        <h2 style={{marginTop: "-0.05em"}}>Palpites<br/>populares</h2>
                        <p>Se não sabes quem<br/> escolher, vê o que está a dar que falar.</p>
                    </div>
                    <div className={"content__votes"}>
                        <div className={"content__votes-label"}>
                            <h4 className={"percentage"}>Votos</h4>
                            <h4 className={"category"}>Categoria</h4>
                            <h4 className={"name"}>Nome</h4>
                        </div>
                        {mostVoted ? mostVoted.map((mv, index) => {
                            let [answer, movie] = mv.answer_text.split("_");
                            const answerText = answer.trim() + (movie ? ` (${movie})` : "");
                            return (
                                <div className={"content__votes-item"} key={"most-voted-" + index}>
                                    <p className={"percentage hide-mobile-palpites"}>{Math.round(mv.percentage)}%</p>
                                    <p className={"category"}>{mv.question_text}</p>
                                    <p className={"name hide-mobile-palpites"} onMouseEnter={() => setPlayMarquee(mv.question_id)} onMouseLeave={() => setPlayMarquee(null)}><Marquee play={playMarquee === mv.question_id}>{answerText}</Marquee></p>
                                    <p className={"name hide-desktop-palpites"}>{answer.trim()} - {Math.round(mv.percentage)}%</p>
                                    {movie && <p className={"name hide-desktop-palpites"}>({movie})</p>}
                                </div>)
                        }) : <div className={"loading-container"}>
                            <div className="lds-ripple">
                                <div/>
                                <div/>
                            </div>
                        </div>}
                    </div>
                </div>}
            </div>
            <div className={"prize"}>
                <div className={"content container-medium"}>
                    <div className={"title-container"}>
                        <h1>L.A. ESPERA</h1>
                        <div>
                            <h1>POR TI</h1>
                            <div className={"subtitle simple-text"}>Só com “ai e tal” não vais lá.<br/>
                                Transforma os teus bitaites em <br/>palpites certeiros e mostra que<br/>percebes realmente do assunto.
                            </div>
                        </div>
                    </div>
                    <div className={"trip-details"}>
                        <div className={"info-line"}>
                            <img src={icon_aviao} alt={"icon_aviao"}/>
                            <p>2 PAX</p>
                        </div>
                        <div className={"info-line"}>
                            <img src={icon_hotel} alt={"icon_hotel"}/>
                            <p>HOTEL 4*</p>
                        </div>
                        <div className={"info-line"}>
                            <img src={icon_lua} alt={"icon_lua"}/>
                            <p>5 NOITES</p>
                        </div>
                        <div className={"info-line"}>
                            <img src={icon_camera} alt={"icon_camera"}/>
                            <div>
                                <p>VISITA</p>
                                <p>ESTÚDIOS</p>
                            </div>
                        </div>
                        <button className={"button"} onClick={() => app.showRegister()}>participar</button>
                    </div>
                </div>
            </div>
            <div className={"how-to-play"}>
                <div className={"container-medium"}>
                    <div className={"label"}>
                        <h2>Como participar</h2>
                        <p>Já tens os teus palpites? Ou ainda estás pelos<br/>bitaites? De qualquer forma, podes participar.</p>
                    </div>
                    <div className={"steps-container"}>
                        <div className={"step"}>
                            <div>
                                <h2>1</h2>
                                <h5>ESCOLHE<br/>O TEU MODO</h5>
                            </div>
                            <p className={"description"}>No <strong>MODO PALPITES</strong>,<br/> quem decide és tu.<br/>No <strong>MODO BITAITES</strong>, a tua escolha é aleatória.</p>
                        </div>
                        <div className={"step"}>
                            <div>
                                <h2>2</h2>
                                <h5>ADIVINHA<br/>OS VENCEDORES</h5>
                            </div>
                            <p className={"description"}>Se foste pelo <strong>MODO PALPITES</strong>, escolhe o vencedor de cada uma das 23 categorias.</p>
                        </div>
                        <div className={"step"}>
                            <div>
                                <h2>3</h2>
                                <h5>ENVIA AS TUAS<br/>ESCOLHAS</h5>
                            </div>
                            <p className={"description"}>Se  ainda não estás confortável, podes gravar os teus palpites e enviá-los mais tarde.</p>
                            <a className={"button"} href={"https://canalhollywood.pt/wp-content/uploads/2025/01/HWD_Palpites_Oscares_2025_regulamento-2.pdf"} target={"_blank"}>REGULAMENTO</a>
                            {/*<a className={"button grey"} href={"https://canalhollywood.pt/wp-content/uploads/2024/01/Palpites-dos-Oscares-2024-Faqs.pdf"} target={"_blank"}>VER FAQS</a>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"play-the-game"} style={{backgroundImage: `url(${background_2})`}}>
                <div className={"wrapper container-medium"}>
                    <div>
                        <h2>MODO PALPITES</h2>
                        <p className={"simple-text"}>Sabes do que falas? Então não percas tempo com bitaites e participa.</p>
                        <div className={"clock-time"}>
                            <img src={clock_blue} alt={"clock"}/>
                            <p>10 MINUTOS</p>
                        </div>
                        <button className={"button"} onClick={() => app.showRegister()}>participar</button>
                    </div>
                    <div>
                        <h2>MODO BITAITES</h2>
                        <p className={"simple-text"}>Sem certezas, mas com vontade de ir a L.A.? Participa na mesma, não perdes nada.</p>
                        <div className={"clock-time"}>
                            <img src={clock_dark_blue} alt={"clock"}/>
                            <p>10 SEGUNDOS</p>
                        </div>
                        <button className={"button"} onClick={() => app.showRegister(true)}>participar</button>
                    </div>
                </div>
            </div>
        </>}
        <div className={"footer"}>
            <div className={"container-medium"}>
                <div>
                    <h4>Tens dúvidas?</h4>
                    <p className={"contact-us"}>
                        Envia-nos um e-mail para:<br/>
                        <a href={"mailto:apoioaocliente@palpitesdososcares.pt"}>apoioaocliente@palpitesdososcares.pt</a>
                    </p>
                </div>
                <div>
                    <h4>COM O APOIO DE:</h4>
                    <a target={"_blank"} href={"https://radiocomercial.pt/"}><img src={logoRadioComercial} alt={"rádio_comercial"}/></a>
                </div>

            </div>
        </div>
    </div>;
}
