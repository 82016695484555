import {useApp} from "../../AppProvider";
import {useEffect, useRef, useState} from "react";
import {Element, scroller} from 'react-scroll';
import styles from './quiz.module.scss';
import Question from "../../components/question";
import Collapsible from "react-collapsible";
import groupArray from "../../misc/groupArray";
import ItemsCarousel from "react-items-carousel";
import Social from "../../components/social";
import usePrevious from "../../misc/usePrevious";
import arrow_down from "../../assets/icons/arrow_down.svg";
import layer from "../landing/assets/layer_1.svg";


export default function Quiz({data, defaultQuestion}) {
    let app = useApp();
    let [questions, setQuestions] = useState(data);
    let [selectedQuestion, setSelectedQuestion] = useState(questions.questions[defaultQuestion] || questions.questions.find(q => !q.answered) || questions.questions[0]);
    let [pickerOpen, setPickerOpen] = useState(false);
    let slideRefs = useRef([]);
    let [sliderHeight, setSliderHeight] = useState(0);

    useEffect(() => {
        setPickerOpen(false);
        updateHeight();
        if (sliderHeight > 0)
            scroller.scrollTo('questions', {
                duration: 300,
                delay: 20,
                smooth: true,
                offset: window.innerWidth <= 800 ? -130 : -112
            });
    }, [selectedQuestion]);

    function updateHeight() {
        setTimeout(() => {
            const height = slideRefs.current[getQuestionIdx()]?.clientHeight || 0;
            setSliderHeight(height);
        }, 100);
    }

    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    });

    const referral_remaining = 3 - app.data.referral_count;
    const remaining = questions.questions.length + app.data.referral_count - questions.questions.map(q => q.answered ? q.answer.length : 0).reduce((a, b) => a + b, 0);
    const questionsRemaining = questions.questions.filter(q => !q.answered).length;
    const questionsRemainingPrevious = usePrevious(questionsRemaining);

    useEffect(() => {
        if (questionsRemainingPrevious > 0 && questionsRemaining === 0 && !app.data.participation?.ended_at) {
            setPickerOpen(true);

            scroller.scrollTo('submit', {
                duration: 300,
                delay: 200,
                smooth: true,
                offset: -window.innerHeight * 0.5
            });
        }
    }, [questionsRemaining])

    if (!selectedQuestion)
        return null;

    function getQuestionIdx(question = null) {
        return questions.questions.findIndex(q => q.id === (question?.id || selectedQuestion.id));
    }

    function setAnswered(question, answer) {
        question.answered = answer.length > 0;
        question.answer = answer;
        //questions.questions[getQuestionIdx(question)] = question;
        setQuestions({...questions});
    }

    async function submit() {
        app.submitQuiz();
    }

    function save() {
        setTimeout(() => {
            setPickerOpen(false);
            app.setPopup(<>
                <h3>Votos guardados</h3>
                <p className={"mt-1"}>Podes voltar a qualquer momento para completar a tua participação.</p>
                <p>Partilha o passatempo com 3 amigos e ganha 3 respostas extra.</p>
                <a className={"button"} onClick={() => {
                    app.setPopup(null);
                    app.setGameOpen(false)
                }}>Fechar</a>
            </>);
        }, 100)
    }

    const current = getQuestionIdx();

    return <div className={styles.Quiz}>
        <div className={"layer-left"} style={{backgroundImage: `url(${layer})`}}/>
        <div className={"layer-right"} style={{backgroundImage: `url(${layer})`}}/>
        <div className={"header"}>
            <div className={"app-container"}>
                <div className={"actions"}>
                    <a onClick={() => app.setGameOpen(false)}>Voltar</a>
                    <a onClick={app.logout}>Sair</a>
                </div>
                <div className={"main"}>
                    <div className={"welcome"}>
                        <h3><span>{app.data.user.name},</span> <span>VAI UM PALPITE?</span></h3>
                    </div>
                    <p className={"text"}>Tens {remaining} resposta{remaining === 1 ? "" : "s"} para dar.
                        {referral_remaining > 0 && <><br/>Partilha com {referral_remaining} amigo{referral_remaining === 1 ? "" : "s"} e ganha mais {referral_remaining}.</>}
                    </p>
                    <Social/>
                </div>
            </div>
        </div>
        <Element name="questions">
            <div className={"navigation app-container"} onClick={() => setPickerOpen(!pickerOpen)}>
                <div>
                    <h4>{selectedQuestion.text}</h4>
                    <img src={arrow_down} className={pickerOpen ? "up" : undefined} alt={"arrow-down"}/>
                </div>
                <h4>Categoria {getQuestionIdx() + 1}/{questions.questions.length}</h4>
            </div>
            <Collapsible open={pickerOpen} trigger={<></>} contentInnerClassName={"categorias app-container"}>
                <div className={"seletor"}>
                    {groupArray(questions.questions, 2).map((group, idx) => <div>
                        {group.map(question => <p key={"question_" + idx}
                                                  className={question.answered ? "voted" : ""}
                                                  onClick={() => setSelectedQuestion(question)}>
                            <span>{getQuestionIdx(question) + 1}.</span> {question.text}
                            {question.answered && <> - {question.answer?.map(a => a.text.replace("_", " / ")).join(", ")}</>}
                        </p>)}
                    </div>)}
                </div>
                <div className={"actions"}>
                    <div>
                        <h4>Ainda sem certezas?</h4>
                        <a className="button blue" onClick={save}>Guardar votação</a>
                    </div>
                    {questionsRemaining === 0 && !app.data.participation?.ended_at && <Element name={"submit"} className={"submit"}>
                        <h4>É desta?</h4>
                        <a className="button blue" onClick={submit}>Submeter votação</a>
                    </Element>}
                </div>
            </Collapsible>
            <div className={"slider_wrapper app-container"}>
                <div className={"arrow_navigation"}>
                    {getQuestionIdx() > 0 && <span className={"previous"} onClick={() => setSelectedQuestion(questions.questions[getQuestionIdx() - 1])}/>}
                    <h4>{getQuestionIdx() + 1} / {questions.questions.length}</h4>
                    {getQuestionIdx() < questions.questions.length - 1 && <span className={"next"} onClick={() => setSelectedQuestion(questions.questions[getQuestionIdx() + 1])}/>}
                </div>
                <div className={"slider"} style={{minHeight: sliderHeight || undefined}}>
                    <ItemsCarousel
                        disableSwipe={true}
                        activeItemIndex={current}
                        numberOfCards={1}
                        gutter={0}>
                        {questions.questions.map((question, idx) => {
                            return <div key={question.id} ref={r => {
                                slideRefs.current[idx] = r;
                            }}>
                                {Math.abs(current - idx) <= 1 && <Question key={question.id} current={current === idx} question={question} onAnswer={(answer) => setAnswered(question, answer)}/>}
                            </div>;
                        })}
                    </ItemsCarousel>
                </div>
                <div className={"buttons-navigation"}>
                    {getQuestionIdx() > 0 &&<span onClick={() => setSelectedQuestion(questions.questions[getQuestionIdx() - 1])}>ANTERIOR</span>}
                    {getQuestionIdx() < questions.questions.length - 1 && <span onClick={() => setSelectedQuestion(questions.questions[getQuestionIdx() + 1])} style={{marginLeft: "auto"}}>PRÓXIMO</span>}
                </div>
            </div>
        </Element>
    </div>;
}
