import icon_facebook from "../../assets/icons/facebook_icon.svg";
import icon_whatsapp from "../../assets/icons/whatsapp_icon.svg";
import icon_email from "../../assets/icons/email.svg";
import icon_share from "../../assets/icons/share_icon.svg";
import icon_x from "../../assets/icons/x.svg";
import {useApp} from "../../AppProvider";
import styles from './social.module.scss';
import copy_regular from './copy-regular.svg';
import {useEffect, useState} from "react";

let ShareComponent = ({app, url}) => {
    const [copied, setCopied] = useState(false);

    return <div className={"popup"}>
                <span className={"close"} onClick={() => {
                    app.setPopup(null);
                    setCopied(false);
                }}/>
        <h4>Partilhar</h4>
        <p style={{marginTop: "15px"}}>Partilha o seguinte link com os teus amigos para ganhar mais votos:<br/>
            <span
                onClick={() => {
                    navigator.clipboard.writeText(url);
                    setCopied(true);
                }}
                style={{cursor: "pointer"}}
            >{url} <img src={copy_regular} style={{width: 20}}/></span></p>
        {copied && <p>Link copiado</p>}
        <div className={"bottom"}>
            <button className={"button"} onClick={() => {
                app.setPopup(null);
                setCopied(false);
            }}>Fechar
            </button>
        </div>
    </div>
};

export default function Social() {
    const app = useApp();

    const url = app.data.base_url + "?referral=" + app.data.user.referral_code;

    function formatDate(dateString) {
        const date = new Date(dateString);

        return new Intl.DateTimeFormat('pt-PT', { day: 'numeric', month: 'long' }).format(date);
    }

    const shareText = `Deixa-te de bitaites e habilita-te a ganhar uma viagem a Los Angeles com tudo incluído. Participa nos Palpites dos Óscares até ${formatDate(app.data.quiz?.ends_at)} e mostra que sabes do que falas: ` + url;

    useEffect(() => {
        const currentLocation = new URL(window.location.href);
        let openReferral = currentLocation.searchParams.get("openReferral");
        if (openReferral) {
            app.setPopup(<ShareComponent app={app} url={url}/>)
            //remove the query param
            currentLocation.searchParams.delete("openReferral");
        }
    }, []);

    return <div className={styles.Social + " social"}>
        <a target={"_blank"} href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}><img src={icon_facebook} alt={"icon_facebook"}/></a>
        <a target={"_blank"} href={"https://wa.me/?text=" + shareText}><img src={icon_whatsapp} alt={"icon_whatsapp"}/></a>
        {/*<a target={"_blank"} href={"mailto:?subject=" + encodeURI("Passadeira para LA") + "&body=" + encodeURI(*/}
        {/*    "Achas que sabes quem vai ganhar os Óscares? Então, estende a passadeira às tuas apostas e habilita-te a ganhar uma viagem para Los Angeles para 2 pessoas, com tudo incluído. Só tens de clicar no link ali em baixo e tentar acertar na maior quantidade de vencedores.\n\n" +*/}
        {/*    "Mas há mais: partilha o teu link com outros amigos e ganha até 3 respostas extra. \n\n" +*/}
        {/*    "Boas apostas!\n\n" +*/}
        {/*    url*/}
        {/*)}><img src={icon_email}/></a>*/}
        <a target={"_blank"} href={"https://twitter.com/intent/tweet?text=" + shareText}><img src={icon_x} alt={"icon_x"}/></a>
        <a target={"_blank"} onClick={() => {
            app.setPopup(<ShareComponent app={app} url={url}/>)
        }}><img src={icon_share}/></a>
    </div>
}
